<template>
	<div>
		<div style="margin-top: -20px;padding-top: 10px;position: sticky;top: -10px;background: #FFF;z-index: 99;">
			<Header :title="isEdit ? '修改商品' : '新增商品'" @back="onBack(false)"></Header>
		</div>
		<a-spin :spinning="loading">
			<div id="content" style="padding-top: 26px;">
				<a-form ref="form" name="form" :model="modelRef" scrollToFirstError :labelCol="{span: 4, xxl: 3}"
					:wrapperCol="{span: 15, xxl: 14 }">
					<div class="ui-card">
						<h3 id="1" class="ui-card__header">商品信息：</h3>

						<a-form-item label="商品名称" name="mainTitle" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-input v-model:value="modelRef.mainTitle" placeholder="请输入商品名称"></a-input>
						</a-form-item>

						<a-form-item label="商品子标题" name="secondTitle" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-input v-model:value="modelRef.secondTitle" placeholder="请输入商品子标题"></a-input>
						</a-form-item>

						<!--            <a-form-item>-->
						<a-form-item label="商品分类" name="classifyId" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-select v-model:value="modelRef.classifyId" placeholder="请选择商品分类">
								<a-select-option v-for="item in classifyList" :key="item.id"
									:value="item.id">{{ item.title }}</a-select-option>
							</a-select>
						</a-form-item>
						<!--              <a-button type="primary">
                <Icon icon="ReloadOutlined"></Icon>
              </a-button>
            </a-form-item>-->

						<a-form-item label="商品标签" name="goodsLabelId">
							<a-select v-model:value="modelRef.goodsLabelId" placeholder="请选择商品标签">
								<a-select-option v-for="item in labelList" :key="item.id"
									:value="item.id">{{ item.title }}</a-select-option>
							</a-select>
						</a-form-item>

						<a-form-item label="商品编号" name="goodsNo">
							<a-input v-model:value="modelRef.goodsNo" placeholder="请输入商品编号"></a-input>
						</a-form-item>

						<a-form-item label="商品售价" name="nowPrice" :rules="[{required: true, message: '必填项不允许为空'}]"
							extra="如果此商品没有规格时，商品售价为商品价格。如果此商品有规格时，商品售价则取规格定价，此价格用于列表展示。">
							<a-input-number v-model:value="modelRef.nowPrice" prefix="￥" style="width: 30%" />
						</a-form-item>

						<a-form-item label="划线价" name="originPrice" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-input-number v-model:value="modelRef.originPrice" prefix="￥" style="width: 30%" />
						</a-form-item>

						<!--            先隐藏-->
            <a-form-item label="积分" name="pointDeduction">
              <a-input-number v-model:value="modelRef.pointDeduction" style="width: 30%" />
            </a-form-item>

						<a-form-item label="商品库存" name="stock">
							<a-input-number v-model:value="modelRef.stock" style="width: 30%" />
						</a-form-item>

						<a-form-item label="基础销量" name="virtualSoldNum" extra="小程序展示销售=实际销售+基础销量，不填写则为0">
							<a-input-number v-model:value="modelRef.virtualSoldNum" style="width: 30%" />
						</a-form-item>

						<a-form-item label="优先级" name="sort" extra="数值越大，优先级越高，不填写则为0">
							<a-input-number :min="0" :precision="0" style="width: 30%" v-model:value="modelRef.sort"
								placeholder="请输入优先级"></a-input-number>
						</a-form-item>

						<a-form-item label="封面图片" :rules="[{required: true, message: '必填项不允许为空'}]"
							extra="建议图片比例：335 : 340">
							<div style="position: relative;">
								<a-upload list-type="picture-card" v-model:file-list="imgUrl"
									action="/admin/ajaxUpload.do" accept="image/*" @change="handleCoverChange">
									<div v-if="imgUrl.length < 1">
										<Icon icon="PlusOutlined"></Icon>
										<div class="ant-upload-text">Upload</div>
									</div>
								</a-upload>
							</div>
						</a-form-item>

						<a-form-item label="轮播图" :rules="[{required: true, message: '必填项不允许为空'}]" extra="建议图片比例：1 : 1">
							<div style="position: relative;">
								<a-upload list-type="picture-card" v-model:file-list="bannerImgs"
									action="/admin/ajaxUpload.do" accept="image/*" @change="handleHomepageChange">
									<div v-if="bannerImgs.length < 5">
										<Icon icon="PlusOutlined"></Icon>
										<div class="ant-upload-text">Upload</div>
									</div>
								</a-upload>
							</div>
						</a-form-item>

						<a-form-item label="商品详情" name="detail">
							<div style="position: relative;z-index: 99;">
								<Editor v-model:value="modelRef.detail"></Editor>
							</div>
						</a-form-item>
					</div>

					<!--          <div class="ui-card">
            <h3 id="2" class="ui-card__header">规格信息：</h3>
            <a-form-item label="规格设置">
              <a-table style="width: 1000px;" :columns="columns" :dataSource="list" :pagination="false" bordered>
                <template #bodyCell="{ column, record, index }">
                  <template v-if="column.key === 'name'">
                    <a-input style="width: 200px;" placeholder="规格" v-model:value="record.name" />
                  </template>
                  <template v-if="column.key === 'data'">
                    <div>
                      <div style="margin-bottom: 10px; display: flex; align-items: center" v-for="(item, i) in record.data" :key="item">
                        <a-checkbox @change="onCheckboxChange(record, item)" v-model:checked="record.data[i].checked"></a-checkbox>
                        <a-input @focusin="onFocusin(item)" @focusout="onFocusout(item, index, i)" style="width: 300px; margin-left: 5px;" placeholder="子规格" v-model:value="record.data[i].name" />
                        <a-button type="link" @click="onSonNormsDelete(index, i)">删除</a-button>
                      </div>
                    </div>
                  </template>

                  <template v-else-if="column.key === 'action'">
                    <a-dropdown :trigger="['click', 'hover']">
                      <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
                      </a-button>
                      <template #overlay>
                        <a-menu>
                          <div @click="onAddSonNorms(index)">
                            <a-menu-item>
                              添加子规格
                            </a-menu-item>
                          </div>
                          <div @click="onNormsDelete(index)">
                            <a-menu-item>
                              删除
                            </a-menu-item>
                          </div>
                        </a-menu>
                      </template>
                    </a-dropdown>
                  </template>
                </template>

                <template #footer>
                  <a-button :disabled="list.length >= 2" type="primary" @click="addNorms">添加规格</a-button>
                  <span v-if="list.length >= 2" style="margin-left: 10px; color: red">最多只能添加两种规格</span>
                </template>
              </a-table>
            </a-form-item>

            <a-form-item label="规格列表">
              <a-table style="width: 1000px;" :columns="normsColumns" :dataSource="normsList" :pagination="false" bordered>
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'originPrice'">
                    <a-input-number :min="0" :precision="2" style="width: 80px;" v-model:value="record.originPrice" />
                  </template>
                  <template v-if="column.key === 'nowPrice'">
                    <a-input-number :min="0" :precision="2" style="width: 80px;" v-model:value="record.nowPrice" />
                  </template>
                  <template v-if="column.key === 'pointDeduction'">
                    <a-input-number :min="0" :precision="2" style="width: 80px;" v-model:value="record.pointDeduction" />
                  </template>
                  <template v-if="column.key === 'stock'">
                    <a-input-number :min="0" :precision="0" style="width: 80px;" v-model:value="record.stock" />
                  </template>
                </template>
              </a-table>
            </a-form-item>
          </div>-->

					<!-- 规格代码  start -->
					<div class="ui-card" v-if="!modelRef.isCard && !modelRef.isCoupon">
						<h3>规格信息</h3>
						<div style="margin-bottom: 20px;">
							<div>
								<div>
									<a-button type="primary" @click="onSkuDataAdd">新增规格</a-button>
								</div>
								<a-table bordered :data-source="skuData" :columns="skuDataColumns" :pagination="false"
									style="width: 900px;margin-top: 10px;">
									<template #bodyCell="{ column, text, index, record }">
										<template v-if="column.dataIndex === 'name'">
											<a-input v-model:value="record.name" placeholder="请输入规格名"
												@change="onSkuNameChange(record, index)" />
										</template>
										<template v-if="column.dataIndex === 'data'">
											<div style="margin-bottom: 14px;">
												<a-button type="primary" size="small" style="max-width: 80px;"
													@click="onAddSkuItem(record, index)">新增{{ record.name }}</a-button>
											</div>
											<div>
												<span v-for="(item, subIndex) in record.data"
													style="display: block;margin-right: 20px;margin-bottom: 20px;">
													<a-checkbox v-model:checked="item.checked"
														@change="onSkuDataChecked(record,index,subIndex)">
														<a-input v-model:value="item.label"
															@change="onSubSkuChange(record,index,subIndex)"></a-input>
													</a-checkbox>
													<a-button type="text" size="small"
														style="margin-left: 10px;color: red;"
														@click="onSubSkuDelete(record,index,subIndex)">删除</a-button>
												</span>
											</div>
										</template>
										<template v-if="column.dataIndex === 'action'">
											<a @click="onSkuDelete(record, index)">删除</a>
										</template>
									</template>
								</a-table>
							</div>
						</div>
						<!-- sku list -->
						<div>
							<a-table bordered :data-source="skuList" :columns="skuListColumns" :pagination="false"
								style="width: 900px;margin-bottom: 20px;">
								<template #bodyCell="{ column, text, index, record }">
									<template v-if="column.dataIndex === 'nowPrice'">
										<a-input-number :min="0" :precision="2" v-model:value="record.nowPrice"
											placeholder="请输入" />
									</template>
									<template v-if="column.dataIndex === 'originPrice'">
										<a-input-number :min="0" :precision="2" v-model:value="record.originPrice"
											placeholder="请输入" />
									</template>
									<template v-if="column.dataIndex === 'pointDeduction'">
										<a-input-number :min="0" :precision="0" v-model:value="record.pointDeduction"
											placeholder="请输入" />
									</template>
									<template v-if="column.dataIndex === 'stock'">
										<a-input-number :min="0" :precision="0" v-model:value="record.stock" placeholder="请输入" />
									</template>
									<template v-if="column.dataIndex === 'imgUrl'">
										<a-upload list-type="picture-card" v-model:file-list="record.imgUrl"
											action="/admin/ajaxUpload.do"
											@change="handleSkuChange($event, record, index)">
											<div v-if="(record.imgUrl && !record.imgUrl.length) || !record.imgUrl">
												<Icon icon="PlusOutlined"></Icon>
												<div class="ant-upload-text">Upload</div>
											</div>
										</a-upload>
									</template>
								</template>
							</a-table>

						</div>
					</div>
					<!-- 规格代码  end -->

					<div class="ui-card">
						<h3 class="ui-card__header">销售信息：</h3>
						<a-form-item label="总限购" name="maxBuyNum" extra="单个用户最多限制购买数量，不填默认不限购">
							<a-input-number v-model:value="modelRef.maxBuyNum" :min="0" :precision="0" style="width: 30%" />
						</a-form-item>
						<a-form-item label="每日限购" name="timeBuyNum" extra="单个用户单日最多限制购买数量，不填默认不限购">
							<a-input-number v-model:value="modelRef.timeBuyNum" :min="0" :precision="0" style="width: 30%" />
						</a-form-item>
					</div>

					<div class="ui-card">
						<h3 class="ui-card__header">卡券配置：</h3>
						<a-form-item label="包含卡" name="isCard">
							<a-switch v-model:checked="modelRef.isCard" :checked-value="1" :unchecked-value="0" />
						</a-form-item>
						<a-form-item v-if="modelRef.isCard" label="包含卡">
							<a-form-item-rest>
								<selectCard v-model:value="cardIdList" @change="(dataList) => {cardList = dataList}">
								</selectCard>
							</a-form-item-rest>
							<a-form-item-rest>
								<div style="margin-top: 10px; width: 700px;" v-if="cardList.length">
									<a-table :pagination="false" :columns="cardColumns" :dataSource="cardList"
										rowKey="id" :scroll="{ x: 500 }">
										<template #bodyCell="{ column, record, index }">
											<template v-if="column.key === 'cardType'">
												<div v-if="record.cardType === 1">普通卡</div>
												<div v-if="record.cardType === 2">主题卡</div>
												<div v-if="record.cardType === 3">礼品卡</div>
											</template>
											<template v-if="column.key === 'num'">
												<a-input-number v-model:value="record.num" :min="0" :precision="0" placeholder="请输入"></a-input-number>
											</template>
											<template v-if="column.key === 'unionCardParPrice'">
												<a-input-number
													:min="0" :precision="2" placeholder="请输入"
													v-model:value="record.unionCardParPrice"></a-input-number>
											</template>
											<template v-if="column.key === 'action'">
												<a-button type="link" @click="onCardDelete(record, index)">删除</a-button>
											</template>
										</template>
									</a-table>
								</div>
							</a-form-item-rest>
						</a-form-item>

						<a-form-item label="包含券" name="isCoupon">
							<a-switch v-model:checked="modelRef.isCoupon" :checked-value="1" :unchecked-value="0" />
						</a-form-item>
						<a-form-item v-if="modelRef.isCoupon" label="包含券">
							<a-form-item-rest>
								<selectCoupon v-model:value="couponIdList" :selectItems="couponList"
									@change="(dataList) => {couponList = dataList}"></selectCoupon>
							</a-form-item-rest>
							<a-form-item-rest>
								<div style="margin-top: 10px; width: 700px;" v-if="couponList.length">
									<a-table :pagination="false" :columns="couponColumns" :dataSource="couponList"
										rowKey="id" :scroll="{ x: 500 }">
										<template #bodyCell="{ column, record, index }">
											<template v-if="column.key === 'type'">
												<div v-if="record.type === 1">代金券</div>
												<div v-if="record.type === 2">兑换券</div>
											</template>
											<template v-if="column.key === 'useLimit'">
												<div v-if="record.useLimit === 1">影片</div>
												<div v-if="record.useLimit === 2">演出</div>
												<div v-if="record.useLimit === 3">卖品</div>
												<div v-if="record.useLimit === 4">商城</div>
											</template>
											<template v-if="column.key === 'num'">
												<a-input-number v-model:value="record.num" :min="0" :precision="0" placeholder="请输入"></a-input-number>
											</template>
											<template v-if="column.key === 'action'">
												<a-button type="link"
													@click="onCouponDelete(record, index)">删除</a-button>
											</template>
										</template>
									</a-table>
								</div>
							</a-form-item-rest>
						</a-form-item>
					</div>

					<div class="ui-card">
						<h3 id="3" class="ui-card__header">物流信息：</h3>
						<a-form-item label="配送方式" name="deliveryType" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-radio-group v-model:value="modelRef.deliveryType" button-style="solid"
								@change="this.modelRef.verifyStoreId = null">
								<a-radio-button :value="0">无</a-radio-button>
								<a-radio-button :value="1">快递发货</a-radio-button>
								<a-radio-button :value="2">到店核销</a-radio-button>
							</a-radio-group>
						</a-form-item>

						<a-form-item label="所属影院" name="verifyStoreId" v-if="modelRef.deliveryType == 2"
							:rules="[{required: true, message: '必填项不允许为空'}]">
							<a-select allow-clear v-model:value="modelRef.verifyStoreId" placeholder="请选择所属影院"
								@change="modelRef.deliveryType = 2">
								<a-select-option v-for="item in cinemaAllList" :key="item.id"
									:value="item.id">{{ item.name }}</a-select-option>
							</a-select>
						</a-form-item>
					</div>

					<div style="margin-top: 30px;text-align: center;">
						<a-button type="primary" html-type="submit" @click="onSubmit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
					</div>
				</a-form>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import selectCoupon from "@/components/select/selectCoupon";
	import selectCard from "@/components/select/selectOneCard";
	import Editor from '@/components/editor/editor';
	import {
		getCinemaAllList
	} from "../../../../service/modules/cinema";
	import {
		getGoodsAllClassifyList,
		getGoodsLabelSearchList
	} from "../../../../service/modules/mall";
	import {
		getGoodsDetail,
		saveGoods,
		updateGoods
	} from "../../../../service/modules/goods";

	export default {
		components: {
			Icon,
			Header,
			selectCoupon,
			selectCard,
			Editor,
		},
		props: {
			isEdit: {
				type: Boolean,
				default: false
			},
			id: {
				type: null,
				default: 0
			},
			type: { // 1平台操作  2上架商城
				type: Number,
				default: 1
			},
			form: {
				type: String,
				default: 'template'
			},
		},
		data() {
			return {
				dataName: '',
				cardIdList: [],
				cardList: [],
				cardColumns: [{
					title: '卡名称',
					dataIndex: 'title',
				}, {
					title: '卡类型',
					key: 'cardType',
				}, {
					title: '数量',
					key: 'num',
				}, {
					title: '面值',
					key: 'unionCardParPrice',
				}, {
					title: '操作',
					key: 'action',
				}],
				couponIdList: [],
				couponList: [],
				couponColumns: [{
					title: '券标题',
					dataIndex: 'title',
				}, {
					title: '券类型',
					key: 'type',
				}, {
					title: '券用途',
					key: 'useLimit',
				}, {
					title: '数量',
					key: 'num',
				}, {
					title: '操作',
					key: 'action',
				}],
				normsList: [{
					colour: '红色',
					norms: 'xxxx',
					minPrice: 1,
					nowPrice: 1,
					price: 1,
					costPrice: 1,
					inventory: 1,
					goodsNum: 1,
				}, {
					colour: '红色',
					norms: 'xxxx',
					minPrice: 1,
					nowPrice: 1,
					price: 1,
					costPrice: 1,
					inventory: 1,
					goodsNum: 1,
				}, {
					colour: '红色',
					norms: 'xxxx',
					minPrice: 1,
					nowPrice: 1,
					price: 1,
					costPrice: 1,
					inventory: 1,
					goodsNum: 1,
				}, {
					colour: '绿色',
					norms: 'xxxx',
					minPrice: 1,
					nowPrice: 1,
					price: 1,
					costPrice: 1,
					inventory: 1,
					goodsNum: 1,
				}, {
					colour: '绿色',
					norms: 'xxxx',
					minPrice: 1,
					nowPrice: 1,
					price: 1,
					costPrice: 1,
					inventory: 1,
					goodsNum: 1,
				}],
				normsColumns: [{
						align: "center",
						title: "颜色",
						dataIndex: 'colour',
						customCell: (data, index) => {
							if (index === 0) {
								return {
									rowSpan: 3,
								};
							}
							if (index === 1) {
								return {
									rowSpan: 0,
								};
							}
							if (index === 2) {
								return {
									colSpan: 0,
								};
							}
						}
					},
					// {
					//   align: "center",
					//   title: "规格",
					//   dataIndex: 'norms',
					// },
					{
						align: "center",
						title: "原价",
						key: 'originPrice',
					}, {
						align: "center",
						title: "售价",
						key: 'nowPrice',
					}, {
						align: "center",
						title: "积分",
						key: 'pointDeduction',
					}, {
						align: "center",
						title: "库存",
						key: 'stock',
					}
				],
				columns: [{
					align: "center",
					title: "规格名",
					key: 'name'
				}, {
					align: "center",
					title: "子规格",
					key: 'data'
				}, {
					align: "center",
					title: "操作",
					key: 'action'
				}],
				list: [],
				coverList: [],
				homepageList: [],
				loading: false,
				cinemaAllList: [],
				classifyList: [],
				modelRef: {
					deliveryType: 0
				},
				labelIds: [],
				labelList: [],
				skuDataColumns: [{
					title: '规格名',
					dataIndex: 'name',
					width: '200px'
				}, {
					title: '规格名',
					dataIndex: 'data',
					width: '600px'
				}, {
					title: '操作',
					dataIndex: 'action',
					width: '100px'
				}],
				skuCheckbox: [1],
				skuData: [
					// {
					//   name: 'size',
					//   data: [{
					//     label: 'big',
					//     value: 1,
					//     checked: false
					//   },{
					//     label: 'small',
					//     value: 2,
					//     checked: false
					//   }]
					// }
				],
				skuListColumns: [
					//   {
					//   title: '',
					//   dataIndex: 'level1',
					//   width: '200px'
					// },{
					//   title: '',
					//   dataIndex: 'level2',
					//   width: '200px'
					// },
					{
						title: '售价',
						dataIndex: 'nowPrice',
						width: '200px'
					}, {
						title: '原价',
						dataIndex: 'originPrice',
						width: '200px'
					}, {
						title: '积分',
						dataIndex: 'pointDeduction',
						width: '200px'
					}, {
						title: '库存',
						dataIndex: 'stock',
						width: '200px'
					}, {
						title: '图片',
						dataIndex: 'imgUrl',
						width: '200px'
					}
				],
				skuList: [
					// {
					//   skuIndex: '1,1',
					//   level1: 1,
					//   level2: 1,
					//   nowPrice: 20,
					//   originPrice: 23,
					//   pointDeduction: 20,
					//   stock: 100,
					// }
				],
				bannerImgs: [],
				imgUrl: [],
				originSkuList: [], // 初始化数据, 用户匹配
			}
		},
		created() {
			this.getAllCinema();
			this.getGoodsAllClassifyList();
			this.getGoodsLabelSearchList();
			if (this.id) {
				// id不为空 isEdit = false 为复制商品，走新增接口
				this.getData();
			}
		},
		methods: {
			addNorms() {
				let obj = {
					name: '',
					data: [{
						name: '',
						checked: false
					}]
				};
				this.list.push(obj);
			},
			onAddSonNorms(index) {
				this.list[index].data.push({
					name: '',
					checked: false
				});
			},
			onSonNormsDelete(index, i) {
				this.$confirm({
					title: '提示',
					content: '确认要删除吗？',
					onOk: () => {
						this.list[index].data.splice(i, 1);
					}
				})
			},
			onNormsDelete(index) {
				this.$confirm({
					title: '提示',
					content: '确认要删除吗？',
					onOk: () => {
						this.list.splice(index, 1);
					}
				})
			},
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async getAllCinema() {
				this.loading = true;
				try {
					let ret = await getCinemaAllList({});
					this.loading = false;
					if (ret.code === 200) {
						this.cinemaAllList = ret.data;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getGoodsLabelSearchList() {
				this.loading = true;
				try {
					let ret = await getGoodsLabelSearchList({});
					this.loading = false;
					if (ret.code === 200) {
						this.labelList = ret.data.list;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getGoodsAllClassifyList() {
				let res = await getGoodsAllClassifyList({
					classifyType: 1
				});
				if (res.code === 200) {
					let classifyList = res.data.filter(item => {
						item.children = res.data.filter(subItem => {
							return subItem.parentId === item.id;
						});
						return !item.parentId
					});
					this.classifyList = classifyList;
				}
			},
			handleCoverChange(info) {
				if (info.file.status === 'done') {
					//console.log(info.file);
					this.imgUrl = info.fileList;
					const $fileList = [...this.imgUrl];
					this.imgUrl = $fileList.map(file => {
						if (file.response) {
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			},
			handleHomepageChange(info) {
				if (info.file.status === 'done') {
					//console.log(info.file);
					this.bannerImgs = info.fileList;
					const $fileList = [...this.bannerImgs];
					this.bannerImgs = $fileList.map(file => {
						if (file.response) {
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			},
			onCouponDelete(item, index) {
				let i = this.couponIdList.indexOf(item.id);
				this.couponIdList.splice(i, 1);
				this.couponList.splice(index, 1);
			},
			onCardDelete(item, index) {
				let i = this.cardIdList.indexOf(item.id);
				this.cardIdList.splice(i, 1);
				this.cardList.splice(index, 1);
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getGoodsDetail({
						id: this.id
					});
					// console.log(JSON.stringify(ret))
					this.loading = false;
					if (ret.code === 200) {
						if (ret.data.goods.bannerImgs) {
							this.bannerImgs = ret.data.goods.bannerImgs.split(',').map(item => {
								return {
									url: item,
								}
							})
						}
						if (ret.data.goods.imgUrl) {
							this.imgUrl = ret.data.goods.imgUrl.split(',').map(item => {
								return {
									url: item,
								}
							})
						}
						if (ret.data.goods.couponList) {
							ret.data.goods.couponList.forEach(item => {
								this.couponIdList.push(item.couponId + '');
								item.title = item.couponTitle;
							})
						}
						if (ret.data.goods.unionCardList) {
							ret.data.goods.unionCardList.forEach(item => {
								this.cardIdList.push(item.unionCardId + '');
								item.title = item.cardTitle;
							})
						}
						this.couponList = ret.data.goods.couponList ? ret.data.goods.couponList : [];
						this.cardList = ret.data.goods.unionCardList ? ret.data.goods.unionCardList : [];
						delete ret.data.goods.bannerImgs;
						delete ret.data.goods.imgUrl;

						this.modelRef = ret.data.goods;
						let flag = true;
						this.classifyList.forEach(info => {
							if (info.id === ret.data.goods.classifyId) {
								flag = false;
							}
						})
						if (flag) {
							ret.data.goods.classifyId = null;
						}
						flag = true;
						this.labelList.forEach(info => {
							if (info.id === ret.data.goods.goodsLabelId) {
								flag = false;
							}
						})
						if (flag) {
							ret.data.goods.goodsLabelId = null;
						}
						// 规格信息处理
						if (ret.data.sku) {
							const skuList = [];
							const skuData = [];
							let skuListColumns = [];
							ret.data.sku.guige.forEach((item, index) => {
								const skuDataItem = {
									name: item.name,
									data: [],
									value: (index + 1)
								}
								item.data.forEach((subItem, subIndex) => {
									skuDataItem.data.push({
										label: subItem,
										value: (index + 1) + '-' + (subIndex + 1),
										checked: true
									})
								})
								skuData.push(skuDataItem);
								skuListColumns.push({
									title: item.name,
									dataIndex: 'level' + (index + 1),
									width: '200px',
									key: skuDataItem.value
								})
							})
							ret.data.sku.data.forEach(item => {
								const skuItem = {
									nowPrice: item.nowPrice,
									originPrice: item.originPrice,
									pointDeduction: item.pointDeduction, // 后台没返回,得问问俊寿
									stock: item.stock,
								};
								if (item.imgUrl) {
									skuItem.imgUrl = [{
										url: item.imgUrl
									}];
								} else {
									skuItem.imgUrl = []
								}
								skuData.forEach((skuDataItem, skuDataIndex) => {
									if (item[skuDataItem.name]) {
										if (skuDataIndex == 0) {
											skuItem.level1 = item[skuDataItem.name];
											const level1Key = skuDataItem.data.find(keyItem => {
												return item[skuDataItem.name] == keyItem.label
											})
											skuItem.level1Key = level1Key.value;
										} else if (skuDataIndex == 1) {
											skuItem.level2 = item[skuDataItem.name];
											const level2Key = skuDataItem.data.find(keyItem => {
												return item[skuDataItem.name] == keyItem.label
											})
											skuItem.level2Key = level2Key.value;
										}
									}
									if (skuItem.level2Key) {
										skuItem.skuIndex = skuItem.level1Key + ',' + skuItem.level2Key;
									} else {
										skuItem.skuIndex = skuItem.level1Key + ',0';
									}
								})
								skuList.push(skuItem);
							})
							this.skuData = skuData;
							this.skuList = skuList;
							this.originSkuList = skuList; // 初始化数据, 用户匹配
							this.skuListColumns = skuListColumns.concat(this.initSkuListColumns());
						}
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onCheckboxChange(info, item) {
				if (item.checked) {
					this.normsList.push({
						colour: item.name,
						norms: 'xxx',
						originPrice: null,
						nowPrice: null,
						pointDeduction: null,
						stock: null
					})
				} else {
					this.normsList = this.normsList.filter(i => item.name !== i.colour);
				}
			},
			onFocusin(item) {
				this.dataName = item.name;
			},
			onFocusout(item, index, i) {
				this.list[index].data.forEach((info, a) => {
					if (info.name === item.name && i !== a) {
						this.$message.error('不能命名为已有规格子项名');
						item.name = this.dataName;
						return;
					}
				})
				if (this.dataName !== item.name) {
					this.normsList.forEach(info => {
						if (info.colour === this.dataName) {
							info.colour = item.name;
						}
					})
				}
			},
			async onSubmit() {
				this.$refs.form.validateFields().then(async () => {
					if (this.imgUrl.length === 0) {
						this.$message.error('封面图片不能为空！');
						return;
					}
					if (this.bannerImgs.length === 0) {
						this.$message.error('轮播图不能为空！');
						return;
					}
					if (this.cardList.length === 0 && this.modelRef.isCard) {
						this.$message.error('包含卡不能为空！');
						return;
					}
					if (this.couponList.length === 0 && this.modelRef.isCoupon) {
						this.$message.error('包含券不能为空！');
						return;
					}

					const data = JSON.parse(JSON.stringify(this.modelRef));
					const postData = {
						type: 1, // 类型：1.普通 5.套餐 (小卖商品必选)
						saleType: 1, // 销售类型 1.商品, 2.卖品
						mainTitle: data.mainTitle,
						secondTitle: data.secondTitle,
						nowPrice: data.nowPrice || 0,
						originPrice: data.originPrice || 0,
						stock: data.stock || 0,
						sort: data.sort || 0,
						virtualSoldNum: data.virtualSoldNum,
						detail: data.detail, // TODO需确定
						maxBuyNum: data.maxBuyNum,
						timeBuyNum: data.timeBuyNum,
						deliveryType: data.deliveryType,
						classifyId: data.classifyId,
						goodsLabelId: data.goodsLabelId,
						isCard: data.isCard,
						isCoupon: data.isCoupon,
						verifyStoreId: data.verifyStoreId,
						pointDeduction: data.pointDeduction || 0, // TODO
					}; // 提交信息
					postData.imgUrl = this.imgUrl[0].url;
					const bannerImgs = this.bannerImgs.map(item => {
						return item.url
					});
					postData.bannerImgs = bannerImgs.join(',');
					const skuList = [];
					const indexList = [];
					if (this.skuList.length) {
						let hadEmptySkuData = true;
						for (let i = 0; i < this.skuList.length; i++) {
							const item = this.skuList[i];
							if (item.nowPrice === '') {
								hadEmptySkuData = false;
								break;
							}
							if (item.originPrice === '') {
								hadEmptySkuData = false;
								break;
							}
							if (item.stock === '') {
								hadEmptySkuData = false;
								break;
							}
							// if (item.imgUrl === '') {
							//   hadEmptySkuData = false;
							//   break;
							// }
							skuList.push({
								skuIndex: item.skuIndex,
								nowPrice: item.nowPrice,
								originPrice: item.originPrice,
								pointDeduction: item.pointDeduction,
								stock: item.stock,
								imgUrl: item.imgUrl[0].url,
							})
						}
						if (!hadEmptySkuData) {
							this.$message.error('请填写完整规格信息');
							return;
						}
						this.skuData.forEach((item, index) => {
							const skuDataItem = {
								name: item.name,
								level: (index + 1),
								data: {}
							};
							let isSelected = false;
							item.data.forEach(subItem => {
								if (subItem.checked) {
									isSelected = true;
									skuDataItem.data[subItem.value] = subItem.label;
								}
							})
							if (isSelected) indexList.push(skuDataItem);
						})
					}
					if (!this.modelRef.isCard && !this.modelRef.isCoupon) {
						postData.sku = {
							indexList,
							skuList
						};
					}
					const cardList = [];
					const couponList = [];
					let hadEmptyCardCouponData = true;
					let hadZeroCardCouponData = true;
					if (this.modelRef.isCard) {
						this.cardList.forEach(item => {
							if (item.num == null || item.unionCardParPrice == null) {
								hadEmptyCardCouponData = false;
							}
							if (item.num == 0 || item.unionCardParPrice == 0) {
								hadZeroCardCouponData = false;
							}
							cardList.push({
								unionCardId: item.unionCardId ? item.unionCardId : item.id,
								unionCardParPrice: item.unionCardParPrice,
								num: item.num
							});
						})
						if (!hadEmptyCardCouponData) {
							this.$message.error('包含卡的数量和面值不能为空');
							return;
						}
						if (!hadZeroCardCouponData) {
							this.$message.error('数量或面值不能为0');
							return;
						}
						postData.unionCardList = cardList;
					}
					if (this.modelRef.isCoupon) {
						hadEmptyCardCouponData = true;
						hadZeroCardCouponData = true;
						this.couponList.forEach(item => {
							if (item.num == null) {
								hadEmptyCardCouponData = false;
							}
							if (item.num == 0) {
								hadZeroCardCouponData = false;
							}
							couponList.push({
								couponId: item.couponId ? item.couponId : item.id,
								num: item.num
							})
						})
						if (!hadEmptyCardCouponData) {
							this.$message.error('包含券的数量不能为空');
							return;
						}
						if (!hadZeroCardCouponData) {
							this.$message.error('数量不能为0');
							return;
						}
						postData.couponList = couponList;
					}
					this.loading = true;
					console.log(JSON.stringify(postData), 66666)
					// console.log(JSON.stringify(this.skuList), 555555)
					// this.loading = false;
					try {
						let ret;
						if (this.isEdit) {
							postData.id = this.id;
							ret = await updateGoods(postData);
						} else {
							delete postData.id;
							ret = await saveGoods(postData);
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							this.onBack(true);
						}
					} catch (e) {
						this.loading = false;
					}
				})
			},
			onSkuDataAdd() {
				if (this.skuData.length >= 2) {
					this.$message.warn('最多能添加2级规格');
					return;
				}
				this.skuData.push({
					name: '',
					data: [],
					value: (this.skuData.length + 1)
				});
			},
			onSkuDelete(record, index) {
				this.skuData.splice(index, 1);
				this.onSkuDataChecked();
			},
			onSkuNameChange(record, index) {
				// upd column 数据
				// this.skuListColumns[index].title = record.name;
				if (this.skuList.length == 0) {
					return;
				} else {
					this.skuListColumns.forEach(item => {
						if (item.key == record.value) {
							item.title = record.name;
						}
					})
				}
			},
			onSubSkuChange(record, index, subIndex) {
				// TODO
				const skuData = this.skuData[index].data[subIndex];
				if (skuData.checked) {
					this.skuList.forEach(item => {
						if (item.level1Key == skuData.value) {
							item.level1 = skuData.label;
						}
						if (item.level2Key == skuData.value) {
							item.level2 = skuData.label;
						}
					})
				}
			},
			handleSkuChange(info, record, index) {
				console.log(record.imgUrl)
				if (info.file.status === 'done') {
					const $fileList = [...record.imgUrl];
					record.imgUrl = $fileList.map(file => {
						if (file.response) {
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			},
			onSubSkuDelete(record, index, subIndex) {
				record.data.splice(subIndex, 1)
				this.onSkuDataChecked();
			},
			onAddSkuItem(record, index) {
				let lastValue = 1;
				if (record.data && record.data.length) {
					lastValue = record.data.length + 1;
				}
				record.data.push({
					label: '',
					value: (index + 1) + '-' + (lastValue),
					checked: true
				})
				this.onSkuDataChecked();
				console.log(record)
			},
			onSkuDataChecked() {
				// 重新生成数据
				const sku = [];
				this.skuData.forEach((first) => {
					const firstList = [];
					const subList = [];
					first.data.forEach((sub) => {
						if (sub.checked) {
							subList.push(sub)
						}
					})
					if (subList.length) {
						firstList.push(first);
						sku.push({
							name: first.name,
							value: first.value,
							subList
						})
					}
				})
				// TODO 生成数据, 查看已有的数据, 渲染
				let skuList = [];
				let skuListColumns = [];
				if (sku.length == 0) {
					this.skuList = [];
				} else if (sku.length == 1) {
					sku.forEach(item => {
						item.subList.forEach(subItem => {
							skuList.push({
								skuIndex: subItem.value + ',0',
								level1: subItem.label,
								level1Key: subItem.value,
								nowPrice: '',
								originPrice: '',
								pointDeduction: '',
								stock: '',
								imgUrl: []
							})
						})
						skuListColumns.push({
							title: item.name,
							dataIndex: 'level1',
							width: '200px',
							key: item.value
						})
					});
				} else if (sku.length == 2) {
					const level1Sku = sku[0];
					const level2Sku = sku[1];
					level1Sku.subList.forEach(item => {
						level2Sku.subList.forEach(subItem => {
							skuList.push({
								skuIndex: '' + item.value + ',' + subItem.value,
								level1: item.label,
								level1Key: item.value,
								level2: subItem.label,
								level2Key: subItem.value,
								nowPrice: '',
								originPrice: '',
								pointDeduction: '',
								stock: '',
								imgUrl: []
							})
						})
					})
					sku.forEach((item, index) => {
						skuListColumns.push({
							title: item.name,
							dataIndex: 'level' + (index + 1),
							width: '200px',
							key: item.value
						})
					})
				}
				skuListColumns = skuListColumns.concat(this.initSkuListColumns());
				this.skuList = skuList;
				this.skuListColumns = skuListColumns;
			},
			findOriginSkuData() {
				// 找初始化数据

			},
			initSkuListColumns() {
				return [{
						title: '售价',
						dataIndex: 'nowPrice',
						width: '200px'
					}, {
						title: '原价',
						dataIndex: 'originPrice',
						width: '200px'
					},
					//   {
					//   title: '积分',
					//   dataIndex: 'pointDeduction',
					//   width: '200px'
					// },
					{
						title: '库存',
						dataIndex: 'stock',
						width: '200px'
					}, {
						title: '图片',
						dataIndex: 'imgUrl',
						width: '200px'
					}
				];
			},
			setSkuListData(index, subIndex) {
				// const skuList = this;
				const firstSku = this.skuData[index];
				const subSku = this.skuData[index].data[subIndex];
				const checked = subSku.checked; // 看看是移除还是保存 (TODO 对比已有的后台的数据)
				// skuList为空时
				if (checked) {
					if (!this.skuList.length) {
						const column = {
							title: firstSku.name,
							dataIndex: 'level1',
							width: '200px'
						}
						this.skuListColumns.unshift(column);
						this.skuList.push({
							level1: subSku.label,
							skuIndex: subSku.label
						});
						return
					}
					const skuColumnLevel1 = this.skuListColumns[0];
					const skuFirstItem = this.skuList[0];
					if (index == 0) {

					}
					if (index == 1) {}
				}
				if (!checked) {
					// 取消选择
				}
			},
			addFirstSku(index, subIndex) {
				const column = {
					title: '',
					dataIndex: 'level1',
					width: '200px'
				}
				this.skuListColumns.unshift(column);
			},
		}
	}
</script>

<style scoped>
	.ui-card {
		padding: 0px 30px;
		border-bottom: solid 1px #CCCCCC;
	}

	.ui-card+.ui-card {
		margin-top: 20px;
	}

	.ui-card__header {
		padding-top: 150px;
		margin-top: -150px;
		margin-bottom: 20px;
	}
</style>
